const categoryMatrix = {
  HOME: "Home",
  USER: "User",
  PORTFOLIO: "Portfolio",
  COMPONENTS: "Components",
  APPLICATIONS: "Applications",
  LEETCODES: "LeetCodes",
  FAVORITES: "Favorites",
  BULLETINBOARDS:"BulletinBoards"
};

export default categoryMatrix;
